import { DatePicker } from '@syncfusion/ej2-calendars';
import {load} from "@syncfusion/ej2-charts";

function getDaysInMonth(year, month){
    let temp = new Date(year, month, 0);
    return temp.getDate();
}

export class DatePickerBuilder {
    constructor(id, format, start, depth, errorDivId, maxDate, minDate, callObject, dotNetObject, dotNetFunctionName, placeholder) {
        this.id = id;
        this.errorDivId = errorDivId;
        this.clearCalled = false;

        this.maxDate = new Date(maxDate);
        this.minDate = new Date(minDate);

        if(maxDate === null)
            this.maxDate = null;
        
        if(minDate == null)
            this.minDate = null;

        let date = new Date(maxDate);
        let min = new Date(minDate);

        if(depth === 'Year') {
            date = new Date(date.getFullYear(), date.getMonth(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1), 23, 59, 59);
            min = new Date(min.getFullYear(), min.getMonth(), getDaysInMonth(min.getFullYear(), min.getMonth() + 1), 0, 0, 0);
        }

        this.the_picker = new DatePicker({
            format: format,
            start: start,
            depth: depth,
            strictMode: true,
        });

        let current = this.the_picker;

        this.the_picker.focus = function () {
            // To open the popup upon input click
            current.show();
        }

        if((placeholder !== null) && (placeholder.length !== 0))
            this.the_picker.placeholder = placeholder;

        if(this.maxDate !== null)
            this.the_picker.max = date;

        if(this.minDate !== null)
            this.the_picker.min = min;

        this.parentElem = null;
        this.theElem = null;
        this.addingToClassList = false;
        this.errorsFound = false;
        this.outsideError = false;

        this.callObject = callObject;
        this.dotNetObject = dotNetObject;
        this.dotNetFunctionName = dotNetFunctionName;

        this.the_picker.openOnFocus = true;

        this.the_picker.addEventListener("cleared", (args) => {
            this.clearCalled = true;

            if(this.callObject){
                this.dotNetObject.invokeMethodAsync(this.dotNetFunctionName, null);
            }
        })

        this.the_picker.addEventListener("change", (args) => {
            if (args.value === null && this.parentElem.classList.contains("syncfusion-input-loaded"))
                this.parentElem.classList.remove("syncfusion-input-loaded");
            
            if(!this.addingToClassList && this.parentElem.classList.contains("syncfusion-input-loaded")){
                this.parentElem.classList.remove("syncfusion-input-loaded");
            }
            else{
                this.addingToClassList = false;
            }

            if(this.outsideError){
                this.outsideError = false;

                if(this.parentElem.classList.contains("tce-syncfusion-error")){
                    this.parentElem.classList.remove("tce-syncfusion-error");
                }
            }

            let errors = [];

            if(this.clearCalled){
                this.clearCalled = false;
                this.addErrorsToDiv(errors)

                return;
            }

            if(args.value === null){
                errors.push("Invalid date(s) was entered")
            }
            else{
                if(this.maxDate !== null && args.value > date){
                    errors.push(`Date cannot be larger than ${this.maxDate.toLocaleDateString("en-US")}.`);
                }
                if(this.minDate !== null && args.value < date){
                    errors.push(`Date cannot be smaller than ${this.minDate.toLocaleDateString("en-US")}.`);
                }
            }

            if(this.callObject){
                let val = args.value;

                if (errors.length !== 0)
                    val = null

                this.dotNetObject.invokeMethodAsync(this.dotNetFunctionName, val);
            }

            this.addErrorsToDiv(errors);
        });
    }

    appendThePicker(){
        let elem = document.getElementById(this.id);

        this.the_picker.appendTo(elem);
        this.theElem = elem;
        this.parentElem = elem.parentElement;

        elem.addEventListener("input", (args) => {
            if(this.parentElem.classList.contains("syncfusion-input-loaded"))
                this.parentElem.classList.remove("syncfusion-input-loaded");
            if(this.parentElem.classList.contains("tce-syncfusion-error")){
                this.parentElem.classList.remove("tce-syncfusion-error");
            }
        });


        // this.parentElem.classList.add("custom-error-class");
    }

    updateDate(fromDataModel, newDate){
        if(fromDataModel){
            if(!this.parentElem.classList.contains("syncfusion-input-loaded")){
                this.parentElem.classList.add("syncfusion-input-loaded");
                this.addingToClassList = true;
            }
        }
        this.the_picker.value = newDate;
    }

    readDate() {
        return {
            date: this.the_picker.value,
            errorsFound: this.errorsFound
        }
    }

    disable(){
        this.the_picker.enabled = false;
    }

    enable(){
        this.the_picker.enabled = true;
    }

    updateDateRangeClassAfterRead(){
        if(!this.parentElem.classList.contains("syncfusion-input-loaded"))
            this.parentElem.classList.add("syncfusion-input-loaded");
    }

    addErrorsToDiv(errorList){
        if(this.errorDivId === null || this.errorDivId === "")
            return;

        this.errorsFound = errorList.length !== 0;

        let errorDiv = document.getElementById(this.errorDivId);

        while (errorDiv.firstChild) {
            errorDiv.removeChild(errorDiv.firstChild);
        }

        let ul = document.createElement("ul");

        errorList.forEach((error) => {
            let li = document.createElement("li");
            li.classList.add("text-danger");
            li.innerText = error;
            ul.appendChild(li);
        })

        errorDiv.appendChild(ul)
    }

    deleteDatePicker() {
        this.the_picker.destroy();
    }

    showError(){
        if(!this.parentElem.classList.contains("tce-syncfusion-error")){
            this.parentElem.classList.add("tce-syncfusion-error")
            this.outsideError = true;
        }
    }

    clearError(){
        if(this.parentElem.classList.contains("tce-syncfusion-error")){
            this.parentElem.classList.remove("tce-syncfusion-error");
            this.outsideError = false;
        }
    }

    clearData(){
        if(this.parentElem.classList.contains("syncfusion-input-loaded"))
        {
            this.parentElem.classList.remove("syncfusion-input-loaded");
        }
        this.the_picker.value = null;
        this.parentElem.value = null;

        this.the_picker.refresh();
        
        let elem = document.getElementById(this.id);
        
        if(elem !== null){
            this.theElem = elem;
            this.parentElem = elem.parentElement;
        }
    }
}
