import SignaturePad from "signature_pad";

const redoSize = 5;

export class SignatureBuilder{
    constructor(id, redoButton) {
        let elem = document.getElementById(id);
        this.boundWindowResize = this.windowResize.bind(this);
        this.canvas = elem;
        this.signaturePad = new SignaturePad(elem);
        this.undone = [];
        this.redoButton = redoButton;
        if(this.redoButton)
            this.redoButton.disabled = true;
        
        window.addEventListener('resize', this.boundWindowResize);
        
        let holder = this;
        this.signaturePad.addEventListener("beginStroke", function (){
            holder.undone = [];
            if(holder.redoButton)
                holder.redoButton.disabled = true;
        });
        
        this.windowResize();
    }
    
    windowResize(){
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        let ratio =  Math.max(window.devicePixelRatio || 1, 1);

        // This part causes the canvas to be cleared
        if((this.canvas.offsetWidth !== 0) && (this.canvas.offsetHeight !== 0)){
            this.canvas.width = this.canvas.offsetWidth * ratio;
            this.canvas.height = this.canvas.offsetHeight * ratio;
        }
        else{
            let holder = this
            
            // For whatever reason canvas width is not immediately ready
            // delay needed on startup
            setTimeout(function(){
                holder.canvas.width = holder.canvas.clientWidth * ratio;
                holder.canvas.height = holder.canvas.clientHeight * ratio;

                holder.canvas.getContext("2d").scale(ratio, ratio);
            }, 10);
            
            return;
        }
        
        this.canvas.getContext("2d").scale(ratio, ratio);

        // If you want to keep the drawing on resize instead of clearing it you can reset the data.
        this.signaturePad.fromData(this.signaturePad.toData());
    }
    
    readSignature(){
        this.disableSig()
        return this.signaturePad.toDataURL()
    }
    
    undo(){
        let data = this.signaturePad.toData();
        
        if(data && (data.length !== 0)){
            if(this.undone.length === redoSize){
                this.undone.shift();
            }
            
            let untouched = [...data];
            
            this.undone.push(untouched);
            
            if(this.redoButton)
                this.redoButton.disabled = false;
            
            data.pop();
            this.signaturePad.fromData(data);
        }
    }
    
    redo(){
        if(this.undone.length !== 0){
            let data = this.undone.pop();
            
            this.signaturePad.fromData(data);
        }
        
        if(this.undone.length === 0){
            if(this.redoButton)
                this.redoButton.disabled = true;
        }
    }
    
    clearPad(){
        this.signaturePad.clear();
        this.undone = [];
        if(this.redoButton)
            this.redoButton.disabled = true;
    }
    
    disableSig(){
        this.canvas.classList.add("signature-style-disabled");
        this.signaturePad.fromData(this.signaturePad.toData());
    }
    
    enableSig(){
        this.canvas.classList.remove("signature-style-disabled");
        this.signaturePad.fromData(this.signaturePad.toData());
    }

    detachEvents(){
        window.removeEventListener('resize', this.boundWindowResize);
    }

     isEmpty(){
        return this.signaturePad.isEmpty();
    }
}