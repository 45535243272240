export class PrintManager{
    constructor(id, dotNetObj, window) {
        this.mainWindow = window;
        this.divId = id;
        this.netObj = dotNetObj;
        
        this.iframeId = "iframe-"+id;
    }
    
    startPrint(){
        let content = this.mainWindow.document.getElementById(this.divId);
        let iframe = this.mainWindow.document.createElement('iframe');

        iframe.id = this.iframeId;
        iframe.style.height = "0px";
        iframe.style.width = "0px";
        iframe.style.padding = "0px";
        iframe.style.margin = "0px";
        iframe.style.position = "absolute";
        iframe.style.border = "none";

        let html = `
            <!DOCTYPE html>
            <html lang='en'>
                <head>
                    <title>Print Sheet</title>
                </head>
                <body>
                    ${content.innerHTML}
                </body>
            </html>
        `;
        html = html.replaceAll("col-md", "col-sm");
        
        iframe.srcdoc = html;
        
        let objRef = this;
        
        iframe.onload = function (){
            objRef.addCss(iframe.contentWindow);
        };

        content.appendChild(iframe);
    }
    
    addCss(iframeWindow){
        let objRef = this
        
        iframeWindow.focus();
        iframeWindow.onunload = function () {
            objRef.netObj.invokeMethodAsync("PrintFinished");
        }

        let head = iframeWindow.document.getElementsByTagName('head')[0];

        let link = iframeWindow.document.createElement('link');
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = 'css/bootstrap/bootstrap.min.css';

        link.onload = function (){
            iframeWindow.print();
            iframeWindow.document.close();
            iframeWindow.close();

            objRef.mainWindow.document.getElementById(objRef.iframeId).remove();
        }

        head.appendChild(link);
    }
}