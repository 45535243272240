import { Chart, DataLabel,Legend, ColumnSeries, Category,Tooltip,Zoom,ScrollBar } from '@syncfusion/ej2-charts';
import { EmitType } from '@syncfusion/ej2-base';
import {Data} from "@syncfusion/ej2-charts/src/common/model/data";

Chart.Inject(Chart, ColumnSeries , Category,Tooltip,DataLabel,Legend,Zoom,ScrollBar);

export class ColumnAreaChartBuilder{
    constructor(id,xName,yName) {
        this.id = id;
        this.firstRender = true;
        //this.record = record;
        this.the_chart = new Chart(
            {

                //Initializing Primary X and YAxis
                primaryXAxis: {
                    valueType: 'Category',interval: 1,    format:'n2',

                },

                //Initializing Primary Y Axis
                primaryYAxis:
                    {
                        edgeLabelPlacement: 'Shift',
                        type: 'Column'
                    },

                //Initializing Chart Series
                series: [
                    {
                        type: 'Column',
                        dataSource:[],
                        xName: xName, width: 2,columnWidth:.9,columnSpacing:.2,
                        yName: yName,
                        name: "Days To Submit",
                        format:'n2',
                        marker: {
                            dataLabel: {
                                visible: true,
                                position: 'Top',
                                format:'n2',
                                font: {
                                    fontWeight: '600', color: '#ffffff'
                                },
                            }
                        },
                    }
                ],
                palettes:["#03233d"],
                zoomSettings:{
                    //enableSelectionZooming:true,
                    enablePan:true,
                    enableScrollbar: true,
                    mode: "X",
                },
                tooltip: {
                    enable: true
                }

            }
        );
        // this.the_chart.enableCanvas = true;


    }

    appendTheChart()
    {
        let elem = document.getElementById(this.id);
        this.the_chart.appendTo(elem);
    }


    addData(records)
    {

        let doAnimation = this.firstRender;
        this.the_chart.series[0].dataSource = records;

        if(records.length ===1)
        {
            this.the_chart.series[0].columnWidth = .3
        }
        else
            this.the_chart.series[0].columnWidth = .9;



        this.the_chart.series[0].animation.enable = doAnimation;

        if(this.firstRender)
            this.firstRender = false;



        this.the_chart.refresh();
    }
}