import {
    Chart,
    DataLabel,
    Legend,
    BarSeries,
    Category,
    Tooltip,
    Zoom,
    ScrollBar


} from '@syncfusion/ej2-charts';
import { EmitType } from '@syncfusion/ej2-base';
import {Data} from "@syncfusion/ej2-charts/src/common/model/data";

Chart.Inject(Chart, BarSeries , Category,Tooltip,DataLabel,Legend,Zoom,ScrollBar);

export class BarAreaChartBuilder{
    constructor(id,xName,yName) {
        this.id = id;
        this.firstRender = true;

        this.the_chart = new Chart(
            {

                //Initializing Primary X and YAxis
                primaryXAxis: {
                    valueType: 'Category',interval:1,labelFormat:'n2',isInversed:false,
                    isIndexed:true
                },

                //Initializing Primary Y Axis
                primaryYAxis:
                    {
                        edgeLabelPlacement: 'Shift',
                    },

                //Initializing Chart Series
                series: [
                    {
                        type: 'Bar',
                        dataSource: [],
                        xName:xName, width: 2,columnWidth:.9,columnSpacing:.2,
                        yName: yName,
                        format:'n2',

                        marker: {
                            dataLabel: {
                                visible: true,
                                position: 'Top',
                                format:'n2',
                                font: {
                                    fontWeight: '600', color: '#ffffff'
                                }
                            }
                        }
                    }
                ],
                palettes:["#03233d"],

                zoomSettings:{
                    enablePan:true,
                    enableScrollbar: true,
                    mode: "Y"
                },
                tooltip: {
                    enable: true,
                },
            }
        );




    }

    appendTheChart()
    {
        let elem = document.getElementById(this.id);
        this.the_chart.appendTo(elem);
    }

    addData(records)
    {
        let doAnimation = this.firstRender;


        this.the_chart.series[0].dataSource = records;

        if(records.length ===1)
        {
            this.the_chart.series[0].columnWidth = .3
            this.the_chart.primaryXAxis.zoomFactor = 1;
            this.the_chart.primaryXAxis.zoomPosition = 1;
        }
        else {
            this.the_chart.series[0].columnWidth = .9
            this.the_chart.primaryXAxis.zoomFactor = 1;
            this.the_chart.primaryXAxis.zoomPosition = 1;
        }
        let temp = 6/records.length;
        if(records.length > 6)
        {
            this.the_chart.primaryXAxis.zoomFactor = temp;
            this.the_chart.primaryXAxis.zoomPosition =1;
        }


        this.the_chart.series[0].animation.enable = doAnimation;


        if(this.firstRender)
            this.firstRender = false;



        this.the_chart.refresh();
    }
}