import tippy, {roundArrow} from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';

export class TooltipBuilder{
    constructor(id, templateId, duration) {
        this.template = document.getElementById(templateId);
        this.template.style.display = "block"
        
        tippy(`#${id}`,{
            duration: [1000, duration],
            delay: [200, 200],
            content: this.template,
            theme: "brycer",
            trigger: "manual",
            arrow: roundArrow + roundArrow
        });
        
        this.elem = document.getElementById(id);
        
        this.instance = this.elem._tippy;
    }
    
    open(){
        this.instance.show();
    }
    
    close(){
        this.instance.hide()
    }
}